/**
 * @file Handles date-related operations.
 * */
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'

/**
 * Calculates the time difference between two dates in days.
 *
 * @param earlierDate Should be earlier than laterDate
 * @param laterDate Should be later than earlierDate
 * @param round Set to 'down' by default, can be set to 'up' and 'none'
 * @returns The day difference in milliseconds
 */
function dayDifference(earlierDate: Date, laterDate: Date, round = 'down'): number {
  const differenceInDays = (laterDate.getTime() - earlierDate.getTime()) / (1000 * 3600 * 24)

  if (round === 'down') {
    return Math.floor(differenceInDays)
  } else if (round === 'up') {
    return Math.ceil(differenceInDays)
  } else {
    return differenceInDays
  }
}

/**
 * Adds the days specified to the date provided and return the resultant date.
 * Can supply a negative number for days to minus instead.
 *
 * @param date The date to add days to
 * @param days The days to add to the date supplied
 * @returns The new date after the days are added
 */
function addDays(date: Date, days: number): Date {
  const newDate = new Date(date)
  newDate.setDate(date.getDate() + days)
  return newDate
}

/**
 * Returns a pretty version of the current date.
 * E.g "October 21, 2015"
 *
 * @returns a pretty version of the current date.
 */
function getPrettyCurrentDate(): string {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const
  return new Intl.DateTimeFormat(getHyphenatedCurrentLocale(), options).format(new Date())
}

/**
 * Returns a pretty version of the date parameter.
 *
 * @param  {string} date: Eg: '2022-04-26T05:21:30.185Z'
 * @param  {Intl.DateTimeFormatOptions={year:'numeric'}} options
 * @returns string: Eg: 'April 26, 2022'
 */
function formatDate(
  date: string | Date,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
): string {
  return new Intl.DateTimeFormat(getHyphenatedCurrentLocale(), options).format(new Date(date))
}

/**
 * Returns whether two dates belong to the same day.
 *
 * @param {Date} date1 The first date
 * @param {Date} date2 The second date
 * @returns {boolean} Whether the two dates belong to the same day.
 */
function sameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

/**
 * Returns the date in the local timezone as a string for <input type=datetime-local> elements.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
 * @param {Date} date The date to convert
 * @returns {string} The date in the local timezone as a string.
 */
function toLocalDatetimeString(date: Date): string {
  const YYYY = date.getFullYear().toString().padStart(4, '0')
  const MM = String(date.getMonth() + 1).padStart(2, '0')
  const DD = String(date.getDate()).padStart(2, '0')
  const HH = String(date.getHours()).padStart(2, '0')
  const II = String(date.getMinutes()).padStart(2, '0')
  return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II
}

/**
 * new Date().toISOString() gives us milliseconds, eg '2024-06-21T09:09:17.337Z'
 *
 * This gives us a string rounded to the nearest minute, and without the milliseconds, ie '2024-06-21T09:09:00Z'
 * @param {Date} date
 * @returns {string} ISO timestamp string in UTC.
 */
function toISOStringAtNearestMinute(date: Date): string {
  return new Date(Math.round(date.valueOf() / 60000) * 60000).toISOString().split('.')[0] + 'Z'
}

export {
  addDays,
  dayDifference,
  formatDate,
  getPrettyCurrentDate,
  sameDay,
  toISOStringAtNearestMinute,
  toLocalDatetimeString,
}
